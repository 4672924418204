<template>
  <div class="commonBox payBox" >
    <div class="box">
      <div class="projectDetail">
        <img :src="info.cover" class="projectCover" />
        <div class="projectBox">
          <div class="projectTitle">{{ info.name }}</div>
          <div class="projectDes">{{ info.info }}</div>
        </div>
      </div>
      <div class="infoLine">
        <div class="infoLabel">支付方式</div>
        <div class="infoText">
          <van-icon name="wechat-pay" size="25" color="#1AAD19 "/>微信支付
        </div>
      </div>
      <div class="infoLine">
        <div class="infoLabel">支付金额</div>
        <div class="infoText moneyText">
          ¥ {{ money }}
        </div>
      </div>
      <!-- 提交按钮 -->
      <div class="submitBtn" @click="submitPay">{{isSubmit?'提交中...':'确认支付'}}</div>
    </div>
    
  </div>
</template>

<script>
import { getProjectDonateInfo,getWebchatPay} from "@/api/index.js";
export default {
  name:"wechatPay",
  data () {
    return {
      itemId:"",
      logId:"",
      money:"",
      info:{},
      isSubmit:false,
      //支付需要的参数
      data:{},
      url:""
    }
  },
  components: {

  },
  mounted() {
    this.itemId = this.$route.query.itemId;
    this.logId = this.$route.query.logId;
    this.money = this.$route.query.money;
    this.getDetail()
    this.url = window.location.href
  },
  methods: {
  //获取详情
  async getDetail() {
      const res = await getProjectDonateInfo(this.itemId);
      if (res) {
        if (res.status == 200) {
          this.info = res.data;
        } else {
          this.$toast(res.err_msg);
        }
      }
    },
    //立即支付
    async submitPay(){
      if(this.isSubmit){
        return false
      }
      this.isSubmit = true
      let isWechat = localStorage.getItem('isWechat')
      let type;
      if(isWechat==1){
        type = 'jsapi'
      }else{
        type= 'h5'
      }
      let params = {
        item_id:this.itemId,
        log_id:this.logId,
        type:type
      }
      const res = await getWebchatPay(params)
      if(res.status==200){
        if(isWechat==1){
          console.log("prepay_id",res.data)
          this.data = res.data
          this.addEventWechat()
        }else{
          window.location.href = res.data.h5_url
        }
      }else{
        this.isSubmit = false
        this.$toast(res.err_msg);
      }
    },
    //监听微信支付
    addEventWechat(){
      if (typeof WeixinJSBridge == "undefined") {
      	if (document.addEventListener) {
      		document.addEventListener('WeixinJSBridgeReady',this.onBridgeReady, false);
      	} else if (document.attachEvent) {
      		document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
      		document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
      	}
      } else {
      	// 初始化成功后，注册支付事件处理函数
        this.onBridgeReady()
      }
    },
    onBridgeReady(){
      let that = this
      WeixinJSBridge.invoke(
			    'getBrandWCPayRequest',
			    {
            appId: this.data.appId,//公众号ID，由商户传入
            timeStamp: this.data.timeStamp,//时间戳，自1970年以来的秒数
            nonceStr: this.data.nonceStr,
            package: this.data.package,
            signType: this.data.signType,
            paySign: this.data.paySign
          },
		      function(res){
            that.isSubmit = false
		      	if(res.err_msg == 'get_brand_wcpay_request:ok'){
              window.location.href = that.url
		      	}else{
		      		that.$toast('支付失败' +  res.err_desc);
		      	}
		      }
		    )
    },
  },
}

</script>
<style scoped lang='scss'>
.payBox{
  padding-top: 50rem;
}
.box{
  width: 670rem;
  height: 90vh;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8rem;
  padding:50rem 40rem;
}
.projectDetail {
  display: flex;
  margin-bottom: 55rem;

  .projectCover {
    width: 160rem;
    height: 160rem;
    margin-right: 25rem;
  }

  .projectBox {
    flex: 1;
    padding-top: 5rem;

    .projectTitle {
      width: 100%;
      font-size: 28rem;
      font-weight: 600;
      color: #121212;
      margin-bottom: 20rem;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      /*行数*/
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .projectDes {
      font-size: 20rem;
      color: #333333;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      /*行数*/
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
.infoLine{
  display: flex;
  align-items: center;
  padding:40rem 0;
  border-bottom: 1px solid #e7e7e7;
  .infoLabel{
    width: 120rem;
    font-size: 28rem;
    margin-right: 20rem;
  }
  .infoText{
    display: flex;
    align-items: center;
    font-size: 28rem;
  }
  .moneyText{
    color: red;
    font-weight: 700;
    font-size: 30rem;
  }
}
.submitBtn {
  margin: 300rem auto 0;
  text-align: center;
  line-height: 82rem;
  border-radius: 8rem;
  color: #fff;
  font-size: 30rem;
  background-color: #b8211a;
}
</style>