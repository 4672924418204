
<template>
  <div>
    <div class="loginBox ">
      <div class="loginDetail bindBox">
        <div class="loginLine">
          <span class="labelText">姓名</span>
          <div class="inputBox">
            <van-field v-model="name" label-width="0"  placeholder="请输入姓名"/>
          </div>
        </div>
        <div class="loginLine">
          <div class="mobileIconBox bindMobile">
            <span class="labelText">手机</span>
            <span class="iconText bindNum">+86</span>
            <img src="img/sel.png" class="selectIcon">
          </div>
          <div class="mobileLine"></div>
          <div class="inputBox">
            <van-field v-model="mobile" label-width="0"  type="tel"  placeholder="请输入手机号"/>
          </div>
        </div>
        <div class="loginLine">
          <span class="labelText">验证码</span>
          <div class="msgNumBox">
            <van-field v-model="code" label-width="0"  type="digit"  placeholder="请输入短信验证码"/>
          </div>
          <div :class="isCode?'bindCode':'bindCode activeBindCode'" @click="showCodeModel">
            {{ timeText }}
          </div>
        </div>
      </div>
      <div class="loginBtn bindBtn" @click="submitBind">提交</div>
    </div>
    <!-- 验证码图形弹窗 -->
    <!-- <div class="coverBox" v-if="isModel"></div>
    <div class="codeModel" v-if="isModel">
      <div class="modelTitle">输入以下数字获取短信验证码</div>
      <div class="imgBox" @click="getImgCodes">
        <img :src="imgUrl" class="codeImg">
        <span class="refreshText">点击刷新</span>
      </div>
      <van-field v-model="codeNum" label-width="0"  placeholder="请输入图片中的验证码" class="codeIput"/>
      <div class="codeBtns">
        <div :class="isCode?'btnItem':'btnItem confirmBtn'" @click="getCodeNum">{{timeText}}</div>
        <div class="btnItem" @click="cancleModel">取消</div>
      </div>
    </div> -->
  </div>
  
</template>

<script>
import { getImgCode,checkImgCode,getBind,getCodes} from '../api';

export default {
  name: 'Bind',
  data() {
    return {
      mobile:'',//手机号码
      code:'',//验证码
      name:'',//姓名
      // codeNum:'',//图形验证码
      // isModel:false,//弹窗
      // imgUrl:'',
      // isCode:false,
      // timeText:"确认",
      isCode: false,
      timeText: "点击获取",
      timeFlag:null,//定时器
    };
  },

  mounted() {
    
  },

  methods: {
    //打开图形验证弹窗
    showCodeModel(){
      if(!this.mobile.trim()){
        this.$toast('请输入手机号')
        return false
      }
      if (!this.isCode) {
        this.isCode = true;
        let seconds = 60;
        this.timeText = `${seconds}s`;
        this.getMobileCode();
        this.timeFlag = setInterval(() => {
          if (seconds > 0) {
            seconds -= 1;
            this.timeText = `${seconds}s`;
          } else {
            clearInterval(this.timeFlag);
            this.isCode = false;
            this.timeText = "再次获取";
          }
        }, 1000);
      }
    },
    //获取手机验证码
    async getMobileCode(){
      let params = {
          mobile:this.mobile,
          type:'bind_mobile'
        }
        const res = await getCodes(params)
        if(res.status==200){
            this.$toast({
            message:"验证码已发送",
          })
        }else{
          this.isCode = false
          clearInterval(this.timeFlag)
          this.timeText = "再次获取"
          this.$toast(res.err_msg)
        }
    },
    //获取验证码弹窗
    getCodeNum(){
      if(!this.codeNum.trim()){
        this.$toast("请输入验证码")
        return false
      }
      if(!this.isCode){
        this.isCode = true
        this.timeText = '获取中...'
        this.checkCodes()
      }
    },
    //取消获取
    cancleModel(){
      this.isModel = false
      this.timeText = '确认'
      this.isCode = false
    },
    //提交
    async submitBind(){
      if(!this.mobile.trim()){
        this.$toast('请输入手机号')
      }else if(!this.code.trim()){
        this.$toast('请输入验证码')
      }else if(!this.name.trim()){
        this.$toast('请输入姓名')
      }else{
        let params = {
            mobile:this.mobile,
            code:this.code,
            truename:this.name,
          }
          const res = await getBind(params)
          console.log(res,"res")
          if(res.status==200){
            let fromRoute = localStorage.getItem('fromRoute')
            this.$toast({
              message:res.data,
              onClose:()=>{
                this.$router.push({ path:fromRoute})
              }
            })
          }else{
            this.$toast(res.err_msg)
          }
      }
    },
    //获取图像验证码
    async getImgCodes(){
      const res = await getImgCode()
      if(res.status==200){
        this.imgUrl = res.data
      }else{
        this.$toast(res.err_msg)
      }
    },
    //验证图形验证码
    async checkCodes(){
      const res = await checkImgCode(this.codeNum)
      this.timeText = "确认"
      if(res.status==200){
        this.getMobileCode()
      }else{
        this.isCode = false
        this.$toast(res.err_msg)
      }
    },
    
  },
};
</script>

<style lang="scss" scoped>
.loginBox{
  height: 100vh;
  width: 100%;
  background-image: url(../assets/login.jpg);
  background-size: 750rem 585rem;
  background-repeat: no-repeat;
  background-color: #f5f5f5;
  padding-top: 100rem;
}
.bindMobileBox{
  font-size: 35rem;
  text-align: center;
  font-weight: 700;
  margin-bottom: 80rem;
}
.logoImg{
  display: block;
  width: 168rem;
  height: 168rem;
  margin: 0 auto 68rem;
}
.loginText{
  font-size: 46rem;
  text-align: center;
  margin-bottom: 65rem;
}
.loginDetail{
  width: 600rem;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8rem;
  padding: 0 30rem;
}
.loginLine{
  display: flex;
  align-items: center;
  color:#a1a1a1;
  padding: 40rem 5rem 20rem;
  border-bottom: 1px solid #e9e8e8;
}
.loginLine:last-child{
  border-bottom:0;
}
.mobileIconBox{
  width: 180rem;
  display: flex;
  align-items: center;
}
.mobileIcon{
  width: 34rem;
  height: 44rem;
  margin-right: 25rem;
}
.iconText{
  font-size: 28rem;
  color:#a1a1a1;
  margin-right: 28rem;
}
.selectIcon{
  width: 13rem;
  height: 7rem;
}
.mobileLine{
  width: 1rem;
  height: 30rem;
  background-color: #d6d6d6;
}

.msgIcon{
  width: 42rem;
  height: 36rem;
}
.msgNumBox{
  width: 320rem;
}
.codeBtn{
  width: 165rem;
  height: 60rem;
  line-height: 60rem;
  border-radius: 8rem;
  text-align: center;
  font-size: 26rem;
  color: #a1a1a1;
  border: 1px solid #a1a1a1;
}
.activeCode{
  color: #b8211a;
  border: 1px solid #b8211a;
}
.loginBtn{
  width: 600rem;
  height: 82rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36rem;
  color: #fff;
  background-color: #b8211a;
  border-radius: 8rem;
  margin:65rem auto;
  letter-spacing: 1.2em;
  padding-left: 1.2em;
}
.registerBtn{
  width: 600rem;
  height: 82rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36rem;
  color: #fff;
  background-color: #b8211a;
  border-radius: 8rem;
  margin:65rem auto;
  margin-bottom: 95rem;
}
.mineIcon{
  width: 37rem;
  height: 37rem;
  margin-right: 5rem;
}
.agreementBox{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 28rem;
}
.tips{
  color: #a1a1a1;
  margin-bottom: 15rem;
}

/* 绑定样式 */
.bindBox{
  width: 680rem;
}
.labelText{
  display: block;
  width: 90rem;
  font-size: 30rem;
  font-weight: 700;
  color:#000;
}
.bindCode{
  width: 165rem;
  height: 60rem;
  line-height: 60rem;
  border-radius: 8rem;
  text-align: center;
  font-size: 26rem;
  color: #fff;
  background-color: #a1a1a1;
}
.activeBindCode{
  background-color: #b8211a;
}
.bindMobile{
  width: 221rem;
  margin-right: 20rem;
}
.bindNum{
  margin-left: 15px;
}
.bindBtn{
  width: 680rem;
}
.coverBox{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.3;
  z-index: 10;
}
.codeModel{
  width: 585rem;
  height: 620rem;
  position: fixed;
  top: 445rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8rem;
  background-color: #fff;
  z-index: 20;
  display: flex;
  flex-direction: column;
  padding: 65rem 0;
  justify-content: space-between;
  justify-items: center;
}
.modelTitle{
  font-size: 30rem;
  color: #333333;
  text-align: center;
}
.imgBox{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.codeImg{
  width: 389rem;
}
.refreshText{
  font-size: 22rem;
  color:#8e8e8e;
}
.codeIput{
  width: 440rem;
  margin:0 auto;
  padding: 20rem 0;
}
::v-deep .van-cell::after {
    border-bottom: 0;
  }
.codeIput ::v-deep .van-field__control{
  padding:15rem;
  font-size: 24rem;
  text-align: center;
  background-color: #eeeeee;
  border-radius: 8rem;
}
.codeBtns{
  width: 440rem;
  margin:0 auto;
  display: flex;
  justify-content: space-between;
}
.btnItem{
  width: 207rem;
  height: 62rem;
  border-radius: 8rem;
  text-align: center;
  line-height: 62rem;
  color: #fff;
  font-size:28rem;
  font-weight: 700;
  background-color: #616161;
}
.confirmBtn{
  background-color: #b8211a;
}

</style>