<template>
  <div class="commonBox messageBox">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
        <div class="msgList">
				<div class="msgItem" v-for="(item,i) in list">
					<div class="msgDetail">
						<img :src="item.avatar?item.avatar:defaultImage" class="msgImg">
						<div class="msgInfo">
							<div class="msgName">{{ item.nickname }}</div>
							<div class="msgTime">{{ item.add_time }}</div>
							<div class="msgName msgText">{{item.message}}</div>
						</div>
					</div>
				</div>
			</div>
        </van-list>
      </van-pull-refresh>
  </div>
</template>

<script>
import { getMessageList } from "@/api/index.js";
export default {
  name: "message",
  data() {
    return {
      itemId:"",
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      //分页信息
      pageIndex: 0,
      totalNum: 50,
    };
  },

  mounted() {
    this.itemId = this.$route.query.id;
  },

  methods: {
    //搜索事件
    onSearch() {
      this.list = [];
      this.pageIndex = 1;
      this.getList();
    },
    //获取项目列表
    async getList() {
      let params = {
        item_id:this.itemId,
        page: this.pageIndex,
      };
      const res = await getMessageList(params);
      if (res.status == 200) {
        this.totalNum = res.data.total;
        this.list = [...this.list, ...res.data.data];
        this.refreshing = false;
        this.loading = false;
      } else {
        this.$toast(res.err_msg);
      }
    },
    // 获取列表数据
    onLoad() {
      if (this.list.length < this.totalNum) {
        console.log(this.pageIndex, "第几页");
        this.pageIndex += 1;
        this.getList();
      } else {
        this.finished = true;
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pageIndex = 1;
      this.list = [];
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
  .messageBox{
    min-height: 100vh;
    height: auto;
    padding: 40rem;
  }
  .msgList{
    background: #fff;
    padding: 40rem 25rem;
		.msgItem{
			display: flex;
			width: 100%;
			justify-content: space-between;
			padding:40rem 0 25rem;
			border-bottom: 1px solid #e7e7e7;
			.msgDetail{
				display: flex;
			}
			.msgImg{
				width: 80rem;
				height: 80rem;
				border-radius: 50%;
				margin-right: 20rem;
			}
			.msgInfo{
				width: 400rem;
				margin-right: 50rem;
				.msgName{
					font-size: 22rem;
					color: #333;
				}
				.msgTime{
					font-size: 20rem;
					color: #979797;
					margin: 10rem 0 15rem;
				}
			}
			.likeBox{
				flex:1;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				font-size: 20rem;
				color: #979797;
				.goodIcon{
					margin-right: 5rem;
				}
			}
		}
	}
</style>
