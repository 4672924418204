<template>
  <div class="commonBox wechatBox">
    <img src="../assets/logo.png" class="wechatImg">
    <div class="wechatText">获取你的微信昵称及头像</div>
    <div class="wechatText">访问该页面需要你授权昵称头像才可操作</div>
    <div class="loginBtn" @click="getWechatInfo">{{ code?'授权中...':' 授权' }}</div>
  </div>
</template>
<script>
import { getWechatAuth,getWechatInfo } from "@/api/index.js";
import { mapActions } from 'vuex';
export default {
  name:"wechatLogin",
  data () {
    return {
      code:""
    }
  },
  components: {

  },
  mounted() {
    let code = this.$route.query.code;
    let token = localStorage.getItem('token')
    
    let foromRoute = localStorage.getItem('fromRoute')
    if(token){
      this.$router.push({ path: foromRoute});
    }else{
      this.code = code
      if(code){
        this.getUserInfo(code)
      }else{
        // this.getWechatInfo()
      }
    }
  },
  methods: {
    ...mapActions(['updateToken']),
    //授权登录
    async getWechatInfo(){
      if(this.code){
        return false
      }
      let currentUrl = window.location.href
      const res = await getWechatAuth()
      if(res.status==200){
        console.log(res,"-----")
        // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${res.data.appid}&redirect_uri=${currentUrl}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${res.data.appid}&redirect_uri=${currentUrl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
      }
    },
    //获取微信用户信息
    async getUserInfo(code){
      const res = await getWechatInfo(code)
      this.code = false
      if (res.status == 200) {
        this.$toast({
          message: "授权成功",
          onClose: () => {
            localStorage.setItem("token", res.data);
            sessionStorage.setItem("token", res.data);
            //保存到vuex中
            this.updateToken(res.data)
            
            let fromRoute = localStorage.getItem('fromRoute')
            this.$router.push({ path: fromRoute});
          },
        });
      } else {
        this.$toast({
          message:"授权失败，请重新授权",
          onClose:()=>{
            this.$router.push({ path: "/wechatLogin"});
          },
        })
      }
    }
  },
}

</script>
<style scoped lang='scss'>
.wechatBox{
  padding:270rem 0 ;
  display: flex;
  flex-direction: column;
  align-items: center;
  .wechatImg{
    width: 185rem;
    height: 185rem;
    border-radius: 50%;
    margin-bottom: 70rem;
  }
  .wechatText{
    font-size: 26rem;
    margin-bottom: 30rem;
  }
}
.loginBtn {
  width: 600rem;
  height: 82rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36rem;
  color: #fff;
  background-color: #b8211a;
  border-radius: 41rem;
  margin: 65rem auto 30rem;
}
</style>