<template>
  <!--支付宝支付-->
  <div>
    <div class="title1">支付宝支付</div>
    <div v-html="html"></div>
  </div>
  
</template>

<script>
  import { getAlipayData } from '@/api/index.js'
  export default{
    data(){
      return {
        html:'',
        loading:false,
        itemId:"",
        logId:"",//付成功 页面（项目名称  支付金额  返回个人中心）
      }
    },
    mounted(){
      this.itemId = this.$route.query.itemId;
      this.logId = this.$route.query.logId;
      this.fetchVideoPay()
    },
    methods:{
      async fetchVideoPay(){
        this.loading = true
        const res = await getAlipayData(this.itemId,this.logId)
        if(res.status==200){
          this.html = res.data
          console.log(this.html)
          this.$nextTick(() => {
            document.forms[0].submit() //渲染支付宝支付页面
          })
        }else{
          this.$toast('支付失败')
        }
      }
    },
  }
</script>

<style>
  .title1{font-size: 40rem; color:#000000;}
</style>