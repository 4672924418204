
<template>
  <div class="detailBox">
    <!-- banner图 -->
    <div class="bannerBox">
      <img :src="projectItem.banner"  class="bannerImg">
      <div class="slogan">{{ projectItem.slogan }}</div>
    </div>
    <!-- 简介 -->
    <div class="donateDetail">
      <div class="donateTitle">
        {{ projectItem.together_name }}
      </div>
      <div class="initiator">
        <img :src="userInfo.avatar" class="initiatorImg">
        <span class="nameBox">{{ userInfo.name }}</span> 邀请您一起捐
      </div>
      <div class="countDetail">
        <div class="targetBox">
          <div >{{ projectItem.together_target_money }}</div>
          <div class="targetText">目标金额</div>
        </div>
        <div class="targetLine"></div>
        <div class="targetBox">
          <div class="targetNum">{{ projectItem.together_donated_money }}元</div>
          <div class="targetText">已捐款</div>
        </div>
      </div>
    </div>
    <!-- 关联项目 -->
    <div class="tabBox" @click="goDetail( projectItem.item_id)">
      <img :src="projectItem.cover" class="coverImg">
      <div class="projectBox">
        <div class="tapBox">关联项目</div>
        <div class="projectTitle">{{ projectItem.name }}</div>
        <div class="projectDes">{{ projectItem.info }}</div>
        <div class="projectTotal">线上总筹集:  <span class="projectNum">{{ projectItem.donated_money }}元</span> </div>
      </div>
    </div>
    <!-- 参与一起捐列表 -->
    <div class="donateList">
      <div class="togetherTitle">参与一起捐的还有</div>
      <ul class="listBox">
        <li class="listItem" v-for="(item,i) in donateList" :key="i">
          <img :src="item.avatar" class="mineUrl">
          <span class="itemName">{{ item.username }}</span>
          于{{ item.add_time }}捐赠了  <span class="targetNum">{{ item.money }}</span> 元
        </li>
      </ul>
      <div class="moreBox" @click="goMoreList">查看更多捐赠人<van-icon name="arrow" /> </div>
    </div>
    <!-- 分享 -->
    <img src="../assets/weixin.png" class="weixinImg" @click="shareFn">
    
    <!-- 底部按钮 -->
    <div class="bottomBox">
      <div class="btnItem mineBtn noneBtn" v-if="projectItem.pause==1||projectItem.status==0||projectItem.is_complete==1">
        <span v-if="projectItem.pause == 1">已暂停</span>
        <span v-if="projectItem.status == 0">已停止</span>
        <span v-if="projectItem.is_complete == 1">已完成</span>
      </div>
      <div class="btnItem mineBtn" v-else @click="goOwnDonate">我要捐赠</div>
    </div>
    <!-- 分享蒙层 -->
    <div class="shareModel" v-if="shareFlag">
      <img src="../assets/arrow.png" class="arrowImg">
      <img src="../assets/knowBtn.png" class="knowBtn" @click="shareFlag = false">
    </div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import {getShareInfo,getWechatSdk,getShareCount} from '@/api/index.js'
export default {
  name: 'share',

  data() {
    return {
      togetherId:"",
      projectItem:{},//项目详情
      userInfo:{},//邀请人信息
      linkProject:{},//关联项目信息
      donateList:[ ],//捐赠列表
      //分享蒙头flag
      shareFlag:false,
    };
  },
  created(){
  },
  mounted() {
    this.togetherId = this.$route.query.together_id
    this.getInfo()
  },
  beforeDestroy() {
    // wx.closeWindow();
  },
  methods: {
    //获取详情
    async getInfo(){
      const res = await getShareInfo(this.togetherId)
      if(res.status==200){
        // console.log(res.data)
        this.projectItem = res.data.item
        this.userInfo = res.data.user
        this.donateList = res.data.donate_list
        document.title = this.projectItem.name
        if(this.projectItem.school_id){
          localStorage.setItem('schoolId',this.projectItem.school_id)
        }
        let isWechat = localStorage.getItem('isWechat')
        if(isWechat==1){
          //如果是微信浏览器 调用微信分享
          this.getSdk()
        }
      }else{
        this.$toast(res.err_msg)
      }
    },

    //我要捐
    goOwnDonate(){
      this.$router.push({ path:`/project/ownDonate?id=${this.projectItem.item_id}&together_id=${this.togetherId}`})
    },
    //获取jssdk参数 只有在是微信浏览器的时候用
    async getSdk(){
      var urlCureent = encodeURIComponent(location.href);
      const res = await getWechatSdk({
					url: urlCureent
				})
      if(res.status==200){
        let that=this
        wx.config({
          debug: false, // 开启debug模式，输出日志信息
          appId: res.data.appId,
          timestamp: res.data.timestamp,
          nonceStr: res.data.nonceStr,
          signature: res.data.signature,
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 设置需要使用的API列表
        })
        console.log(window.location.href,that.projectItem,"share")
        // 初始化成功后，注册分享事件处理函数
        wx.ready(() => {
         // 设置好友转发时显示的标题、图片及链接
          wx.updateAppMessageShareData({
            title: that.projectItem.name,
            desc: that.projectItem.info,
            link: window.location.href,
            imgUrl: that.projectItem.cover,
            success:()=>{
              console.log("success"); 
              // 设置成功
            },
            complete:()=>{
              console.log(111111)
            }
          })
        // 设置朋友圈分享时显示的标题、图片及链接
          wx.updateTimelineShareData({
            title: that.projectItem.name,
            link: window.location.href,
            imgUrl: that.projectItem.cover,
            success:()=>{
              // console.log("success2");
              // 设置成功
            }
          })
       })
      }
    },
    //点击分享
    shareFn(){
      this.shareFlag = true
        //记录分享次数,只要点击按钮就记录次数
       this.getShared()
    },
    //记录分享
    async getShared(){
      let params = {
          item_id:this.projectItem.item_id,
          school_id:this.projectItem.school_id
      }
      console.log('记录分享==>')
      const res = await getShareCount(params)
      if(res.status==200){
        console.log("111")
        this.getInfo()
      }
    },
    //一起捐更多列表
    goMoreList(){
      this.$router.push({ path:`/project/moreDonateList?id=${this.projectItem.item_id}&togetherId=${this.togetherId}`})
    },
    //去往详情页面
    goDetail(id) {
      this.$router.push({ path: `/project/projectDetail?id=${id}` });
    },
  },
};
</script>

<style lang="scss" scoped>
.detailBox{
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding-bottom: 93rem;
}
.bannerBox{
  width: 100%;
  height: 421rem;
  position: relative;
  .bannerImg{
    width: 100%;
    height: 421rem;
  }
  .slogan{
    width: 100%;
    position: absolute;
    bottom: 50rem;
    padding: 0 55rem;
    font-size: 30rem;
    color: #fff;
  }
}
.donateDetail{
  width: 100%;
  padding: 40rem;
  background-color: #fff;
  .donateTitle{
    font-size: 36rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20rem;
  }
  .initiator{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22rem;
    color:#6e6e6e;
    padding-bottom: 25rem;
    border-bottom: 1px solid #e7e7e7;
    .initiatorImg{
      width: 80rem;
      height: 80rem;
      border-radius: 40rem;
      margin-right: 25rem;
    }
    .nameBox{
      color:#000;
      margin-right: 5rem;
    }
  }
}
.countDetail{
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 30rem 0 20rem;
  .targetBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    font-size: 30rem;
    font-weight: 700;
    
    .targetText{
      font-size: 18rem;
      color: #717171;
      margin-top: 8rem;
    }
  }
  .targetLine{
    width: 1rem;
    height: 70rem;
    background-color: #e7e7e7;
  }
}
.tabBox{
  margin-top: 20rem;
  padding:48rem 40rem;
  display: flex;
  width: 100%;
  background-color: #fff;
  .coverImg{
    width: 214rem;
    height: 214rem;
    border-radius: 8rem;
    margin-right: 35rem;
  }
  .projectBox{
    flex:1;
    .tapBox{
      width: 90rem;
      line-height: 30rem;
      border-radius: 8rem;
      font-size: 16rem;
      color: #f79d01;
      border: 1px solid #f79d01;
      text-align: center;
      margin-bottom: 20rem;
    }
    .projectTitle{
      font-size: 24rem;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      /*行数*/
      display: -webkit-box;
      -webkit-box-orient: vertical;
      margin-bottom: 15rem;
    }
    .projectDes{
      font-size: 20rem;
      color:#333333;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      /*行数*/
      display: -webkit-box;
      -webkit-box-orient: vertical;
      margin-bottom: 30rem;
    }
    .projectTotal{
      font-size: 20rem;
      color:#333;
      .projectNum{
        font-weight: 600;
        color:#b8211a;
        font-size: 24rem;
      }
    }
  }
}
.donateList{
  margin-top: 25rem;
  padding: 45rem 40rem 60rem;
  background-color: #fff;
  .togetherTitle{
    font-size: 30rem;
    font-weight: 700;
    color:#1d1d1d;
    text-align: center;
    margin-bottom: 20rem;
  }
  .listBox{
    .listItem{
      display: flex;
      align-items:center;
      font-size: 22rem;
      color:#333333;
      padding:25rem 25rem 20rem;
      border-bottom: 1px solid #e7e7e7;
      .mineUrl{
        width: 50rem;
        height: 50rem;
        border-radius: 25rem;
        margin-right: 30rem;
      }
      .itemName{
        font-size: 24rem;
        margin-right: 5rem;
      }
    }
  }
}
.bottomBox{
  position: fixed;
  bottom: 0;
  height: 90rem;
  width: 100%;
  .mineBtn{
    width: 100%;
    height: 90rem;
    line-height: 90rem;
    text-align: center;
    font-size: 28rem;
    color:#fff;
    font-weight: 700;
    background-color: #b8211a;
  }
  .noneBtn{
    background-color: #606060;
  }
}
.weixinImg{
  position: fixed;
  bottom: 720rem;
  right: 0;
  width: 105rem;
  height: 105rem;
}

.targetNum{
  color: #b8211a;
}
.shareModel{
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(0,0,0,0.7);
  .arrowImg{
    display: block;
    width: 503rem;
    height: 378rem;
    margin-top: 2rem;
    margin-left: 152rem;
  }
  .knowBtn{
    display: block;
    width: 252rem;
    height: 90rem;
    margin:45rem auto;
  }
}
.moreBox{
  width: 240rem;
  line-height: 50rem;
  border-radius: 25rem;
  font-size: 22rem;
  text-align: center;
  margin: 50rem auto 0;
  color: #b8211a;
  border: 1rem solid #b8211a;
}
</style>