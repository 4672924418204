<template>
  <div class="agreementBox" v-html="agreementText"></div>
</template>

<script>
import { getAgreement } from "@/api/index.js";
export default {
  name: "Agreement",

  data() {
    return {
      agreementText: "协议内容",
    };
  },

  mounted() {
    this.getAgreements();
  },
  methods: {
    //获取协议内容
    async getAgreements() {
      const res = await getAgreement();
      if (res.status == 200) {
        this.agreementText = res.data;
      } else {
        this.$toast(res.err_msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.agreementBox {
  padding: 60rem 50rem;
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  background-image: url(../assets/agreement.jpg);
  background-repeat: no-repeat;
  background-size: 750rem 277rem;
  font-size: 24rem;
}
</style>
