<template>
  <div class="commonBox paySuccessBox">
    <van-icon name="wechat-pay" size="80" color="#1AAD19"/>
    <div class="success">支付成功</div>
    <div class="btns">
      <div class="loginBtn emptyClass" @click="getUrlParam(1)">返回项目</div>
      <div class="loginBtn" @click="getUrlParam(2)">查看证书</div>
    </div>
    
  </div>
</template>

<script>
// import {getOrderInfo} from '@/api/index.js'
export default {
  data () {
    return {
      outTradeNo:"",//订单号
      id:"",//项目id
      logId:"",
    }
  },
  components: {

  },
  mounted() {
    let mchData ={
      action:'onIframeReady',
      displayStyle:'SHOW_CUSTOM_PAGE',
      height: 960
    }
    let postData = JSON. stringify(mchData)
    parent.postMessage(postData, 'https://payapp.weixin.qq.com')
  },
  methods: {
    getUrlParam(type) {
      let str = window.location.search
		  var reg = new RegExp("(^|&)" + 'out_trade_no' + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
		  var r = window.location.search.substr(1).match(reg); //匹配目标参数
		  if (r != null){
        this.outTradeNo = unescape(r[2])
        if(type==1){
          this.goBack()
        }else{
          this.goCert()
        }
      }
    },
    //  // 根据订单号获取项目信息
    //  async getInfo(type){
    //   const res = await getOrderInfo(this.outTradeNo)
    //   if(res.status==200){
    //     this.id = res.data.item_id
    //     this.logId = res.data.log_id
    //     if(type==1){
    //       this.goBack()
    //     }else{
    //       this.goCert()
    //     }
        
    //   }else{
    //     this.$toast(res.err_msg);
    //   }
    // },
    //返回项目
    goBack(){
      let origin=window.location.origin
      let mchData ={
        action:'jumpOut', 
        // jumpOutUrl:`${origin}/project/projectDetail?id=${this.id}`
        jumpOutUrl:`${origin}/project/projectDetail?outTradeNo=${this.outTradeNo}`
      }
      let postData = JSON. stringify(mchData)
      parent.postMessage(postData, 'https://payapp.weixin.qq.com')
    },
    //查看证书
    goCert(){
      let origin=window.location.origin
      let mchData ={
        action:'jumpOut', 
        // jumpOutUrl:`${origin}/project/certificate?outTradeNo=&logId=${this.logId}&itemId=${this.id}`
        jumpOutUrl:`${origin}/project/certificate?outTradeNo=${this.outTradeNo}`
      }
      let postData = JSON. stringify(mchData)
      parent.postMessage(postData, 'https://payapp.weixin.qq.com')
    }
  },
}

</script>
<style scoped lang='scss'>
.paySuccessBox{
  padding-top: 300rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .success{
    margin: 20rem 0 10rem;
    font-size: 30rem;
  }
  .btns{
    // display: flex;
    // width: 100%;
    // justify-content: space-between;
  }
  .loginBtn {
    width: 600rem;
    height: 82rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30rem;
    color: #fff;
    background-color: #b8211a;
    border-radius: 41rem;
    margin: 65rem auto 30rem;
  }
  .emptyClass{
    color: #b8211a;
    background-color: #fff;
    border: 1px solid #b8211a;
  }
}
</style>