<template>
  <div class="loginBox">
    <img src="../assets/logo.png" class="logoImg" />
    <div class="loginText">欢迎登录</div>
    <div class="loginDetail">
      <div class="loginLine">
        <div class="mobileIconBox">
          <img src="../assets/mobile.png" class="mobileIcon" />
          <span class="iconText">+86</span>
          <img src="../assets/sel.png" class="selectIcon" />
        </div>
        <div class="mobileLine"></div>
        <div class="inputBox">
          <van-field
            v-model="mobile"
            label-width="0"
            type="tel"
            placeholder="请输入手机号"
          />
        </div>
      </div>
      <div class="loginLine">
        <img src="../assets/msg.png" class="msgIcon" />
        <div class="msgNumBox">
          <van-field
            v-model="code"
            label-width="0"
            type="digit"
            placeholder="请输入短信验证码"
          />
        </div>
        <div
          :class="isCode ? 'codeBtn' : 'codeBtn activeCode'"
          @click="getCode"
        >
          {{ timeText }}
        </div>
      </div>
    </div>
    <div class="loginBtn" @click="submitLogin">注册即登录</div>
    <!--<div class="registerBox" @click="goRegister">注册账号</div>-->
  </div>
</template>

<script>
import { getLogin, getCodes } from "@/api/index.js";
import { mapActions } from 'vuex';
export default {
  name: "Login",

  data() {
    return {
      mobile: "", //手机号码
      code: "", //验证码
      isCode: false,
      timeText: "点击获取",
      timeFlag:null,
    };
  },
  created() {
  },
  mounted() {},
  methods: {
    ...mapActions(['updateToken']),
    //获取验证码
    getCode() {
      if (!this.isCode) {
        //先清除本地token 避免出错
        localStorage.setItem('token','')
        this.isCode = true;
        let seconds = 60;
        this.timeText = `${seconds}s`;
        this.sendCode();
        this.timeFlag = setInterval(() => {
          if (seconds > 0) {
            seconds -= 1;
            this.timeText = `${seconds}s`;
          } else {
            clearInterval(this.timeFlag);
            this.isCode = false;
            this.timeText = "再次获取";
          }
        }, 1000);
      }
    },
    //获取验证码请求
    async sendCode() {
      let params = {
        mobile: this.mobile,
        type: "user_login",
      };
      const res = await getCodes(params);
      if (res.status == 200) {
        this.$toast("验证码已发送");
      } else {
        this.$toast(res.err_msg);
        clearInterval(this.timeFlag);
        this.isCode = false;
        this.timeText = "再次获取";
      }
    },
    //登录
    async submitLogin() {
      if (!this.mobile.trim()) {
        this.$toast("请输入手机号");
      } else if (!this.code.trim()) {
        this.$toast("请输入验证码");
      } else {
        let params = {
          mobile: this.mobile,
          code: this.code,
        };
        const res = await getLogin(params);
        console.log(res, "res");
        if (res.status == 200) {
          this.$toast({
            message: "登录成功",
            onClose: () => {
              localStorage.setItem("token", res.data);
              sessionStorage.setItem("token", res.data);
              //保存到vuex中
              this.updateToken(res.data)
              let fromRoute = localStorage.getItem('fromRoute')
              this.$router.push({ path: fromRoute });
            },
          });
        } else {
          this.$toast(res.err_msg);
        }
      }
    },
    //去往注册登录
    goRegister() {
      this.$router.push({ path: "/register" });
    },
  },
};
</script>

<style lang="scss" scoped>
.loginBox {
  height: 100vh;
  width: 100%;
  background-image: url(../assets/login.jpg);
  background-size: 750rem 585rem;
  background-repeat: no-repeat;
  background-color: #f5f5f5;
  padding-top: 180rem;
}
.logoImg {
  display: block;
  width: 168rem;
  height: 168rem;
  margin: 0 auto 68rem;
}
.loginText {
  font-size: 46rem;
  text-align: center;
  margin-bottom: 65rem;
}
.loginDetail {
  width: 600rem;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8rem;
  padding: 0 30rem;
}
.loginLine {
  display: flex;
  align-items: center;
  color: #a1a1a1;
  padding: 40rem 5rem 20rem;
  border-bottom: 1px solid #e9e8e8;
}
.loginLine:last-child {
  border-bottom: 0;
}
.mobileIconBox {
  width: 180rem;
  display: flex;
  align-items: center;
}
.mobileIcon {
  width: 34rem;
  height: 44rem;
  margin-right: 25rem;
}
.iconText {
  font-size: 28rem;
  color: #a1a1a1;
  margin-right: 28rem;
}
.selectIcon {
  width: 13rem;
  height: 7rem;
}
.mobileLine {
  width: 1rem;
  height: 30rem;
  background-color: #d6d6d6;
}
::v-deep .van-field__control {
  border: 0;
  font-size: 28rem;
  color: #a1a1a1;
}

.msgIcon {
  width: 42rem;
  height: 36rem;
}
.msgNumBox {
  width: 320rem;
}
.codeBtn {
  width: 165rem;
  height: 60rem;
  line-height: 60rem;
  border-radius: 8rem;
  text-align: center;
  font-size: 26rem;
  color: #a1a1a1;
  border: 1px solid #a1a1a1;
}
.activeCode {
  color: #b8211a;
  border: 1px solid #b8211a;
}
.loginBtn {
  width: 600rem;
  height: 82rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36rem;
  color: #fff;
  background-color: #b8211a;
  border-radius: 8rem;
  margin: 65rem auto 30rem;
}
.registerBtn {
  width: 600rem;
  height: 82rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36rem;
  color: #fff;
  background-color: #b8211a;
  border-radius: 8rem;
  margin: 65rem auto;
  margin-bottom: 95rem;
}
.mineIcon {
  width: 37rem;
  height: 37rem;
  margin-right: 5rem;
}
.agreementBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 28rem;
}
.tips {
  color: #a1a1a1;
  margin-bottom: 15rem;
}

/* 绑定样式 */
.bindBox {
  width: 680rem;
}
.labelText {
  display: block;
  width: 90rem;
  font-size: 30rem;
  font-weight: 700;
}
.bindCode {
  width: 165rem;
  height: 60rem;
  line-height: 60rem;
  border-radius: 8rem;
  text-align: center;
  font-size: 26rem;
  color: #fff;
  background-color: #a1a1a1;
}
.activeBindCode {
  background-color: #b8211a;
}
.bindMobile {
  width: 221rem;
  margin-right: 20rem;
}
.bindNum {
  margin-left: 15px;
}
.bindBtn {
  width: 680rem;
}
.coverBox {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.3;
  z-index: 10;
}
.codeModel {
  width: 585rem;
  height: 620rem;
  position: fixed;
  top: 445rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8rem;
  background-color: #fff;
  z-index: 20;
  display: flex;
  flex-direction: column;
  padding: 65rem 0;
  justify-content: space-between;
  justify-items: center;
}
.modelTitle {
  font-size: 30rem;
  color: #333333;
  text-align: center;
}
.imgBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.codeImg {
  width: 389rem;
}
.refreshText {
  font-size: 22rem;
  color: #8e8e8e;
}
::v-deep .van-cell::after {
  border-bottom: 0;
}
.codeBtns {
  width: 440rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.btnItem {
  width: 207rem;
  height: 62rem;
  border-radius: 8rem;
  text-align: center;
  line-height: 62rem;
  color: #fff;
  font-size: 28rem;
  font-weight: 700;
  background-color: #616161;
}
.confirmBtn {
  background-color: #b8211a;
}
.registerBox {
  font-size: 24rem;
  color: #b8211a;
  text-align: center;
  text-decoration: underline;
}
</style>
