<template>
  <div class="recordBox">
    <div class="countBox">
      <div class="numberBox">{{totalNum}}笔</div>
      &nbsp;&nbsp;|&nbsp;&nbsp;
      <div class="moneyBox">{{donated}}元</div>
    </div>
    <div class="listBox">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="itemBox" v-for="(item, i) in list" :key="i">
        <img :src="avatar" class="mineImg" />
        <div class="recordDetail">
          <div class="recordNum">
            {{nickname}}
            <span class="lightNum">{{ item.money }}</span
            >元
          </div>
          <div class="recordTime">{{item.add_time}}</div>
        </div>
        <div class="certifiBtn" @click="goCertificate(item)">查看证书 <van-icon name="arrow" /></div>
      </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { getMyDonateList } from '@/api/index.js'

export default {
  name: "recordList",

  data() {
    return {
      list: [],
      donated:0, //总捐款金额
      loading:false,
      finished:false,
      refreshing:false,
      pageIndex:0,
      totalNum:10,
      avatar:'',
      nickname:''
      
    };
  },

  mounted() {},
  methods: {
    //获取我的捐款记录
    async getList() {      
        const res = await getMyDonateList(this.pageIndex)
        if(res.status==200){
          this.list = this.list.concat(res.data.data.data)
          this.refreshing = false
          this.loading  = false
          this.totalNum = res.data.data.total
          this.donated  = res.data.total_money
          this.nickname = res.data.nickname
          this.avatar   = res.data.avatar
        }else{
          this.$toast(res.err_msg)
        }
    },
    onLoad() { //      
      if(this.list.length < this.totalNum){
        console.log(this.pageIndex,"第几页")
        this.pageIndex+=1
        this.getList()     
      }else{
        this.finished = true
      }
    },
    onRefresh(){ //清空列表数据，并刷新
      this.finished = false
      this.loading = true
      this.pageIndex = 1
      this.list = []
      this.getList()       
    },
    //去往证书详情页面
    goCertificate(item){
      this.$router.push({ path:`/project/certificate?outTradeNo=&logId=${item.log_id}&itemId=${item.item_id}`})
    },
  },
};
</script>

<style lang="scss" scoped>
.recordBox {
  padding: 0rem 45rem 60rem;
}
.countBox {
  padding: 40rem 20rem 30rem;
  display: flex;
  font-size: 30rem;
  font-weight: 600;
  color: #333;
  border-bottom: 1px solid #e7e7e7;
}
.moneyBox {
  color: #b8211a;
}
.itemBox {
  padding: 40rem 0 20rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  .mineImg {
    width: 105rem;
    height: 105rem;
    border-radius: 50%;
    margin-right: 35rem;
  }
  .recordDetail {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 105rem;
    width: 330rem;
    .recordNum {
      font-size: 26rem;
      color: #333;
      .lightNum {
        color: #b8211a;
      }
    }
    .recordTime {
      font-size: 22rem;
      color: #333;
    }
  }
  .certifiBtn{
    line-height: 50rem;
    height: 50rem;
    padding:0 20rem 0 25rem;
    border-radius: 25rem;
    color: #fff;
    font-size: 22rem;
    background-color: #b8211a;
  }
}
</style>
