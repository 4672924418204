<template>
	<div class="detailBox">
		<!-- banner图 -->
		<div class="bannerBox">
			<img :src="info.banner" class="bannerImg" />
			<div class="showBox">
				<div class="showItem">
					<img src="../assets/eye.png" class="eyeIcon" />
					浏览：{{ info.views }}
				</div>
				<div class="showItem">
					<img src="../assets/link.png" class="linkIcon" />
					分享：{{ info.shared }}
				</div>
			</div>
		</div>
		<!-- 简介 -->
		<div class="donateDetail">
			<div class="donateTitle">
				{{ info.name }}
			</div>
			<div class="donateContent">
				<span class="donateText">{{ isOpen ? info.info : clipDetail }}</span>
				<span class="colorNum" @click="isOpen = !isOpen">{{ isOpen ? "收起" : "详情" }} ></span>
			</div>
			<div class="countDetail">
				<div class="targetBox">
					<div>{{ info.funding * 1 || "不限" }}</div>
					<div class="targetText">目标金额</div>
				</div>
				<div class="targetLine"></div>
				<div class="targetBox">
					<div class="targetNum">{{ info.donated_money }}元</div>
					<div class="targetText">已捐款</div>
				</div>
			</div>
		</div>
		<!-- tab栏 -->
		<div class="tabBox">
			<div class="tabNav">
				<div :class="activeTab == item.id ? 'navItem activeNav' : 'navItem'" v-for="(item, i) in navList" :key="i"
					@click="changeNav(item.id)">
					{{ item.name }}
				</div>
			</div>
			<div class="navDetail" v-if="activeTab != 3">
				<div class="detailItem">
					<img :src="item.avatar?item.avatar:defaultImage"  :class="activeImg == item.id ? 'detailImg activeImg' : 'detailImg'"
						v-for="(item, i) in lineList" :key="i" @click="changeImg(item.id, i)" />
				</div>
				<div class="detailText">{{ detailText }}</div>
			</div>
			<div class="navDetail" v-if="activeTab == 3">
				<div class="navDetailItem" v-for="(item, i) in underLineList" :key="i">
					<span style="">{{ item.name }}</span>
					<span>{{ item.wupin||'现金' }}</span>
					<span>{{ item.money?item.money+'元':'-'}}</span>
					<!-- <span>{{ item.jz_time }}</span> -->
				</div>
			</div>
			<div class="donateMore" @click="goMoreList">
				查看更多捐赠（<span class="targetNum">{{currCount}}</span>笔）>
			</div>
		</div>
		<!-- 项目介绍 -->
		<div class="introduceBox">
			<div class="introduceTop">
				<div class="topTitle">项目介绍</div>
				<div class="rightIcon" @click="isAll = !isAll">
					{{ isAll ? "收起" : "展开" }}
					<van-icon :name="isAll ? 'arrow-up' : 'arrow-down'" />
				</div>
			</div>
			<div :class="isAll ? 'introduceText introduceAllHeight' : 'introduceText'" v-html="info.content"></div>
		</div>
		<!-- 留言板-->
		<div class="introduceBox">
			<div class="introduceTop">
				<div class="topTitle">留言</div>
				<div class="rightIcon" @click="goMoreMsg">
					共 <span class="targetNum">{{msgCount}}</span> 条
					<van-icon name="arrow" />
				</div>
			</div>
			<div class="msgList">
				<div class="msgItem" v-for="(item,i) in msgList">
					<div class="msgDetail">
						<img :src="item.avatar?item.avatar:defaultImage" class="msgImg">
						<div class="msgInfo">
							<div class="msgName">{{ item.username }}</div>
							<div class="msgTime">{{ item.add_time }}</div>
							<div class="msgName msgText">{{item.message}}</div>
						</div>
					</div>
					<!-- <div class="likeBox">
						<van-icon name="good-job-o" size="16" class="goodIcon"/>20
					</div> -->
				</div>
			</div>
		</div>
		<!-- 爱心播报 -->
		<div class="heartBox" v-if="heartList.length">
			<img src="../assets/heartImg.png" class="heartImg" />
			<vue-seamless-scroll :data="heartList"  class="scrollBox" :class-option="classOption">
				<div class="scrollUl">
					<div class="scrollItem" v-for="(item, i) in heartList" :key="i">
						<img :src="item.avatar?item.avatar:defaultImage" class="scrollImg" />
						{{ item.username }}于{{item.add_time}}捐赠{{item.money}}元
					</div>
				</div>
			</vue-seamless-scroll>
		</div>
		<!-- 底部按钮 -->
		<div class="bottomBox">
			<div class="mineBox" @click="goMine">
				<img src="../assets/mineIcon.png" class="mineIcon" />
				个人中心
			</div>
			<div v-if="info.pause == 1 || info.status == 0||info.is_complete==1" class="btnItem itemStop">
        <span v-if="info.pause == 1">已暂停</span>
        <span v-if="info.status == 0">已停止</span>
        <span v-if="info.is_complete == 1">已完成</span>
      </div>
      <div class="btnItemParent" v-else>
        <div class="btnItem" @click="donateTogether">发起一起捐</div>
        <div class="btnItem mineBtn" @click="goOwnDonate">我要捐赠</div>
      </div>
			
		</div>

 		<!-- 分享 -->
 		<img src="../assets/weixin.png" class="weixinImg" @click="shareFn">
		<!-- 分享蒙层 -->
    <div class="shareModel" v-if="shareFlag">
      <img src="../assets/arrow.png" class="arrowImg">
      <img src="../assets/knowBtn.png" class="knowBtn" @click="shareFlag = false">
    </div>
	</div>
</template>

<script>
	import wx from 'weixin-js-sdk'
	import {
		getProjectDetail,
		getHeartList,
		getWechatSdk,
		getShareCount,


		getOrderInfo
	} from "@/api/index.js";
	import vueSeamlessScroll from "vue-seamless-scroll";
import { info } from 'sass';
	export default {
		name: "projectDetail",

		data() {
			return {
				//项目id
				projectId: "",
				//项目详情
				info: {},
				clipDetail: "",
				isOpen: false,
				//当前tab
				activeTab: 1,
				navList: [{
						id: 1,
						name: "个人捐赠"
					},
					{
						id: 2,
						name: "一起捐赠"
					},
					{
						id: 3,
						name: "线下捐赠"
					},
				],
				//个人捐 和 一起捐展示列表
				lineList: [],
				//个人捐列表
				person: [],
				//一起捐列表
				together: [],
				//当前照片
				activeImg: 1,
				//捐的信息
				detailText: "",
				//线下捐赠列表
				underLineList: [],
				//项目介绍是否展开
				isAll: true,
				//爱心播报列表
				heartList: [],
				//滚动播报配置项
				classOption: {
					openTouch: false,
					step: 0.5,
					hoverStop: false,
					limitMoveNum: 1,
				},
				//留言板
				msgList:[{}],
				//留言总数
				msgCount:50,
				//捐赠总笔数
				donateNum: 0,
        defaultImage: require('@/assets/avatar.png'),
        currType:'person',
        currCount:0,
        togetherCount:0,
        offlineCount:0,
        personCount:0,
				isFirst:1,
				 //分享蒙头flag
				 shareFlag:false,
			};
		},
		components: {
			vueSeamlessScroll,
		},
		mounted() {
			if(this.$route.query.outTradeNo){
				this.getInfo(this.$route.query.outTradeNo)
			}else{
				this.projectId = this.$route.query.id;
				this.getDetail();
				this.getHeartLists();
			}


			
		},
		beforeDestroy(){
			// wx.closeWindow();
		},
		methods: {
			// 根据订单号获取项目信息
			async getInfo(outTradeNo){
      const res = await getOrderInfo(outTradeNo)
      if(res.status==200){
        this.projectId = res.data.item_id
        this.getDetail();
				this.getHeartLists();
      }else{
        this.$toast(res.err_msg);
      }
    },




			//获取项目详情
			async getDetail() {
				const res = await getProjectDetail(this.projectId);
				if (res.status == 200) {
					this.info = res.data.base;
					document.title = this.info.name
					//在获取详情的时候 操作具体内容长度
					if (this.info.info.length > 100) {
						this.clipDetail = this.info.info.substring(0, 100) + "...";
					} else {
						this.clipDetail = this.info.info;
					}
					this.person = res.data.person;
					this.together = res.data.together;
					this.underLineList = res.data.offline;
					this.lineList = this.person;
					this.msgList = res.data.message
					this.msgCount = res.data.message_count
          this.currCount = res.data.person_count; //个人捐赠笔数
          this.personCount = res.data.person_count
          this.offlineCount = res.data.offline_count
          this.togetherCount = res.data.together_count
					if (this.lineList.length) {
						this.activeImg = this.lineList[0].id;
						this.detailText = `${this.lineList[0].username}于${this.lineList[0].add_time}捐赠${this.lineList[0].money}元`;
					}
					//如果是微信初始化分享
					let isWechat = localStorage.getItem('isWechat')
					if (isWechat == 1) {
						this.getSdk()
					}
          if(this.info.school_id){
            localStorage.setItem('schoolId',this.info.school_id)
          }
				} else {
					this.$toast(res.err_msg);
				}
			},
			//获取爱心播报列表
			async getHeartLists() {
				const res = await getHeartList(this.projectId);
				if (res.status == 200) {
					this.heartList = res.data;
					for (let i = 0; i < 4; i++) {
						// console.log(i,"i")
						if (this.heartList.length < 4) {
							this.heartList = [...this.heartList, ...res.data]
						} else {
							return
						}
					}
				} else {
					this.$toast(res.err_msg);
				}
			},
			//点击分享
			shareFn(){
      this.shareFlag = true
        //记录分享次数,只要点击按钮就记录次数
       this.getShared()
    },
			//获取jssdk参数 只有在是微信浏览器的时候用
			async getSdk() {
				var urlCureent = encodeURIComponent(location.href);
				const res = await getWechatSdk({
					url: urlCureent
				})
				console.log(window.location.href,"detail")
				if (res.status == 200) {
					let that=this
					wx.config({
						debug: false, // 开启debug模式，输出日志信息
						appId: res.data.appId,
						timestamp: res.data.timestamp,
						nonceStr: res.data.nonceStr,
						signature: res.data.signature,
						jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'
						] // 设置需要使用的API列表
					})
					wx.error(function(res) {
						console.log('生成微信签名失败了');
						// config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
					})


					// 初始化成功后，注册分享事件处理函数
					wx.ready(() => {
						wx.updateAppMessageShareData({
							title: this.info.name,
							desc: this.info.info,
							link: window.location.href,
							imgUrl: this.info.banner,
							success:()=>{
								console.log("success"); // 设置成功
							},
							complete:()=>{
              	console.log(111111)
            	}
						});

						wx.updateTimelineShareData({
							title: this.info.name,
							link: window.location.href,
							imgUrl: this.info.banner,
							success:()=>{
								console.log("success2"); // 设置成功
							}
						});
					})
				}
			},
			//记录分享
			async getShared(){
      let params = {
          item_id:this.projectId,
          school_id:this.info.school_id
      }
      console.log('记录分享==>')
      const res = await getShareCount(params)
      if(res.status==200){
        console.log("111")
				this.getDetail()
      }
    },
			//切换tab
			changeNav(id) {
       
				this.activeTab = id;
				//切换列表内容
				if (this.activeTab == 2) { //一起捐
					this.lineList = this.together;
					if (this.lineList.length) {
						this.activeImg = this.lineList[0].id;
						this.detailText = `${this.lineList[0].username}携${this.lineList[0].jk_num}伙伴共捐${this.lineList[0].money}元`;
					}else{
            this.detailText = ''
          }
          this.currCount = this.togetherCount
				} else if (this.activeTab == 1) { //个人
					this.lineList = this.person;
					if (this.lineList.length) {
						this.activeImg = this.lineList[0].id;
						this.detailText = `${this.lineList[0].username}于${this.lineList[0].add_time}捐赠${this.lineList[0].money}元`;
					}else{
            this.detailText = ''
          }
          this.currCount = this.personCount
				}else{ //3. 线下捐赠
          this.currCount = this.offlineCount
        }
			},
			//切换图片
			changeImg(id, i) {
				this.activeImg = id;
				if(this.activeTab == 1){
					this.detailText = `${this.lineList[i].username}于${this.lineList[i].add_time}捐赠${this.lineList[i].money}元`;
				}else if(this.activeTab==2){
					this.detailText = `${this.lineList[i].username}携${this.lineList[i].jk_num}伙伴共捐${this.lineList[i].money}元`;
				}
			},
			//查看更多捐赠
			goMoreList() {
        if(this.activeTab == 1){
          this.$router.push({
            path: `/project/moreDonateList?id=${this.projectId}`,
          });
        }else if(this.activeTab == 2){
           this.$router.push({
            path: `/project/together?id=${this.projectId}`,
          });
        }else{
           this.$router.push({
            path: `/project/offLine?id=${this.projectId}`,
          });
        }
			
			},
			// 去往个人中心
			goMine() {
				this.$router.push({
					path: "/home"
				});
			},
			//发起一起捐
			donateTogether() {
				this.$router.push({
					path: `/project/donateTogether?id=${this.projectId}`,
				});
			},
			//我要捐
			goOwnDonate() {
				this.$router.push({
					path: `/project/ownDonate?id=${this.projectId}`
				});
			},
			//去往留言列表
			goMoreMsg(){
				this.$router.push({
					path: `/project/message?id=${this.projectId}`
				});
			}
		},
	};
</script>

<style lang="scss" scoped>
  
	.detailBox {
		width: 100%;
		min-height: 100vh;
		background-color: #f5f5f5;
		padding-bottom: 93rem;
	}

	.bannerBox {
		width: 100%;
		height: 421rem;
		position: relative;

		.bannerImg {
			width: 100%;
			height: 421rem;
		}

		.showBox {
			width: 100%;
			position: absolute;
			bottom: 30rem;
			display: flex;
			justify-content: space-between;
			padding: 0 30rem 0 60rem;

			.showItem {
				display: flex;
				align-items: center;
				font-size: 18rem;
				color: #fff;

				.eyeIcon {
					width: 24rem;
					height: 14rem;
					margin-right: 8rem;
				}

				.linkIcon {
					width: 20rem;
					height: 20rem;
					margin-right: 8rem;
				}
			}
		}
	}

	.donateDetail {
		width: 100%;
		padding: 40rem;
		background-color: #fff;

		.donateTitle {
			font-size: 30rem;
			margin-bottom: 20rem;
			text-align: center;
		}

		.donateContent {
			position: relative;
			width: 100%;
			font-size: 20rem;
			padding-bottom: 30rem;
			border-bottom: 1px solid #e7e7e7;
		}

		.donateText {
			color: #606060;
			line-height: 30rem;
		}

		.colorNum {
			display: block;
			float: right;
			color: #b8211a;
		}
	}

	.countDetail {
		display: flex;
		align-items: center;
		justify-content: space-around;
		padding: 30rem 0 20rem;

		.targetBox {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 40%;
			font-size: 30rem;
			font-weight: 700;

			.targetText {
				font-size: 18rem;
				color: #717171;
				margin-top: 8rem;
			}
		}

		.targetLine {
			width: 1rem;
			height: 70rem;
			background-color: #e7e7e7;
		}
	}

	.tabBox {
		margin-top: 20rem;
		padding: 48rem 40rem;
		background-color: #fff;

		.tabNav {
			display: flex;

			.navItem {
				padding: 0 30rem;
				line-height: 46rem;
				border-radius: 23rem;
				font-size: 20rem;
				color: #b8211a;
				border: 1rem solid #b8211a;
				margin-right: 15rem;
			}

			.activeNav {
				color: #fff;
				background-color: #b8211a;
			}
		}
	}

	.navDetail {
		padding: 30rem 0;
		border-bottom: 1px solid #e7e7e7;
	}

	.detailItem {
		display: flex;

		.detailImg {
			width: 95rem;
			height: 95rem;
			border-radius: 50%;
			margin-right: 8rem;
		}

		.detailImg:last-child {
			margin-right: 0;
		}

		.activeImg {
			border: 1px solid #b8211a;
		}
	}

	.detailText {
		margin-top: 25rem;
		font-size: 24rem;
		color: #6e6e6e;
		text-align: center;
	}

	.navDetailItem {
		display: flex;
		font-size: 24rem;
		color: #1b1b1b;
		// justify-content: space-between;
		padding: 25rem 20rem 20rem;
		border-bottom: 1px solid #e7e7e7;
		span{
			display: block;
			width: 25%;
		}
		span:nth-child(2){
			width: 50%;
			text-align: center;
		}
		span:last-child{
			width: 25%;
			text-align: center;
		}
	}

	.navDetailItem:last-child {
		border-bottom: 0;
	}

	.donateMore {
		height: 48rem;
		border-radius: 24rem;
		padding: 0 24rem;
		display: flex;
		align-items: center;
		font-size: 20rem;
		color: #6e6e6e;
		width: fit-content;
		border: 1px solid #949494;
		margin: 40rem auto 0;
	}

	.introduceBox {
		margin-top: 20rem;
		padding: 35rem 40rem;
		background-color: #fff;

		.introduceTop {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 20rem;
			border-bottom: 1px solid #e7e7e7;

			.topTitle {
				font-size: 28rem;
			}

			.rightIcon {
				font-size: 20rem;
				color: #6e6e6e;
			}
		}

		.introduceText {
			height: 155rem;
			min-height: 155rem;
			padding: 35rem 0;
			line-height: 40rem;
			font-size: 22rem;
			color: #6d6d6d;
			overflow: hidden;
			img{
				max-width: 100%;
			}
		}

		.introduceAllHeight {
			height: auto;
		}
	}

	.bottomBox {
		position: fixed;
		bottom: 0;
		height: 90rem;
		display: flex;

		.mineBox {
			height: 90rem;
			width: 150rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background-color: #fff;
			font-size: 22rem;
			color: #b8211a;
			font-weight: 700;

			.mineIcon {
				width: 30rem;
				margin-bottom: 8rem;
			}
		}

		.btnItem {
			width: 300rem;
			height: 90rem;
			line-height: 90rem;
			text-align: center;
			font-size: 28rem;
			color: #fff;
			font-weight: 700;
			background-color: #f42c22;
		}
    .itemStop{
        background-color: #606060;
        width:600rem;
      }
      .btnItemParent{width:600rem;display:flex;}
		.mineBtn {
			background-color: #b8211a;
		}
	}

	.targetNum {
		color: #b8211a;
	}

	.heartBox {
		position: fixed;
		bottom: 60rem;
		left: 0;
		width: max-content;
		padding: 0 40rem 45rem;
	}

	.heartImg {
		width: 131rem;
		height: 40rem;
	}

	.scrollBox {
		height: 275rem;
		overflow: hidden;
		width: 100%;
		margin-top: 20rem;

		.scrollUl {
			.scrollItem {
				height: 58rem;
				padding-left: 30rem;
				padding-right: 40rem;
				line-height: 58rem;
				width: -webkit-max-content;
				width: -moz-max-content;
				width: max-content;
				background: rgba(184, 33, 26, 0.5);
				border-radius: 29rem;
				margin-bottom: 20rem;
				font-size: 18rem;
				color: #fff;
				display: flex;
				align-items: center;

				.scrollImg {
					width: 50rem;
					height: 50rem;
					border-radius: 25rem;
				}
			}
		}
	}
	.msgList{
		.msgItem{
			display: flex;
			width: 100%;
			justify-content: space-between;
			padding:40rem 0 25rem;
			border-bottom: 1px solid #e7e7e7;
			.msgDetail{
				display: flex;
			}
			.msgImg{
				width: 80rem;
				height: 80rem;
				border-radius: 50%;
				margin-right: 20rem;
			}
			.msgInfo{
				width: 400rem;
				margin-right: 50rem;
				.msgName{
					font-size: 22rem;
					color: #333;
				}
				.msgTime{
					font-size: 20rem;
					color: #979797;
					margin: 10rem 0 15rem;
				}
			}
			.likeBox{
				flex:1;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				font-size: 20rem;
				color: #979797;
				.goodIcon{
					margin-right: 5rem;
				}
			}
		}
	}
	.weixinImg{
  position: fixed;
  bottom: 720rem;
  right: 0;
  width: 105rem;
  height: 105rem;
}
	.shareModel{
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(0,0,0,0.7);
  .arrowImg{
    display: block;
    width: 503rem;
    height: 378rem;
    margin-top: 2rem;
    margin-left: 152rem;
  }
  .knowBtn{
    display: block;
    width: 252rem;
    height: 90rem;
    margin:45rem auto;
  }
}
</style>