<template>
  <div class="commonBox alipayBox">
    <div style="margin-bottom: 10rem;">请复制该链接到浏览器支付：</div>
    
    <div style="color:#027AFF;">
      {{ baseUrl }}/alipay?itemId={{itemId}}&logId={{logId}}
    </div>
    
  </div>
</template>

<script>
export default {
  data () {
    return {
      baseUrl:location.origin,
      itemId:"",
      logId:"",
    }
  },
  components: {

  },
  mounted() {
    this.itemId = this.$route.query.itemId;
    this.logId = this.$route.query.logId;
  },
  methods: {

  },
}

</script>
<style scoped lang='scss'>
.alipayBox{
  padding:40rem;
  font-size: 30rem;
  word-break: break-all;
}
</style>