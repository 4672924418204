<template>
  <div>
    <div class="certifiBox" v-show="isShow">
      <div  :style="{width:imgWidth}"  class="certBox" id="certBox">
        <img :src="imageUrl" class="certiImg" ref="imgDiv" :style="{width:imgWidth}">
      </div>
      <div class="saveText">长按以上图片保存证书</div>
      <div class="btn" @click="goIndex">进入项目首页</div>
    </div>
    
    <div class="emptyBox" v-show="!isShow">
      证书加载中，请稍等...
    </div>
  </div>
  
</template>

<script>
import html2canvas from 'html2canvas';
import { getMyCertifi ,getOrderInfo} from '@/api/index.js'
export default {
  name: 'certificate',
  data () {
    return {
      isShow:false,
      logId:"",
      itemId:"",
      outTradeNo:"",//订单号
      imageUrl:'',//证书图片
      imgWidth:"",
      imgHeight:"",
      // 证书编号
      certNo:'',
      certText:'',//证书文字
      startTime: null, // 起始时间
      threshold: 200,// 长按阈值，单位为毫秒
      //文字位置
      textTop:'',
      textLeft:'',
      zoomNum:"",//计算屏幕的缩放比例

      baseUrl:"",
    }
  },
  components: {

  },
  mounted() {
    this.logId = this.$route.query.logId;
    this.itemId = this.$route.query.itemId;
    this.outTradeNo = this.$route.query.outTradeNo;
    if(this.outTradeNo){
      this.getInfo(this.outTradeNo)
    }else{
      this.getCertifi()
    }
  },
  methods: {
    // 根据订单号获取项目信息
			async getInfo(outTradeNo){
      const res = await getOrderInfo(outTradeNo)
      if(res.status==200){
        this.logId = res.data.log_id
        this.itemId = res.data.item_id
        this.getCertifi()
      }else{
        this.$toast(res.err_msg);
      }
    },




    //获取证书
    async getCertifi(){
      let params = { 
        log_id:this.logId,
        item_id:this.itemId,
        orders_no:this.outTradeNo
      }
      const res = await getMyCertifi(params)
      if(res.status==200){
          // this.imageUrl =`data:image/png;base64,${res.data.bg_base64}` 
          this.imageUrl = res.data.cert_url?res.data.cert_url:`data:image/png;base64,${res.data.bg_base64}` 
          this.isShow = true
          // setTimeout(()=>{
          //   // this.imgHeight = this.$refs.imgDiv.offsetHeight+'px'
          //   // let imgWidth = 750/this.$refs.imgDiv.offsetWidth
          //   // this.imgHeight =(imgWidth*this.$refs.imgDiv.offsetHeight)+'rem'
          //   this.zoomNum = (750/window.innerWidth).toFixed(2)

          //   console.log(this.zoomNum,"11")
          //   this.imgWidth =( 1000/this.$refs.imgDiv.offsetHeight)*this.$refs.imgDiv.offsetWidth + 'rem'
          //   this.certText = this.pxToRem(res.data.content,this.zoomNum)
          //   this.certNo = res.data.cert_no
          //   this.textTop = `${res.data.top}%`
          //   this.textLeft = `${res.data.left}%`
          //   this.getImg()
          // })
        }else{
          this.$toast(res.err_msg)
        }
    },
    pxToRem(input,zoom){
				return input.replace(/(\d+(\.\d+)?)(px)/g, function(match, p1) {
  			  // return `${parseFloat(p1)*1.45}rem`; // 假设1px等于1/16rem
  			  return `${parseFloat(p1)*2}rem`; // 假设1px等于1/16rem
  			});
			},
    //进入项目首页
    goIndex(){
      this.$router.push({ path:`/project/projectDetail?id=${this.itemId}`})
    },
    //长按先生成图片 在进行本地下载
    getImg(){
      setTimeout(()=>{
       html2canvas(document.getElementById("certBox"), { //superMap整个页面的节点
          backgroundColor: null, //画出来的图片有白色的边框,不要可设置背景为透明色（null）
          useCORS: true, //支持图片跨域
          scale: 1, //设置放大的倍数
          dpi:350
        }).then((canvas) => {
          //截图用img元素承装，显示在页面的上
          let img = new Image();
          img.src = canvas.toDataURL("image/jpg"); // toDataURL :图片格式转成 base64
          console.log(img.src,"生成的图片链接")
          this.baseUrl = img.src





          //如果你需要下载截图，可以使用a标签进行下载
          // let a = document.createElement("a");
          // a.href = canvas.toDataURL("image/jpeg");
          // a.href = img.src;
          // a.download = "捐赠证书";
          // a.click();
      });
     });
   },
    //长按保存图片
    handleMouseDown() {
      this.startTime = new Date().getTime();
      document.addEventListener('mouseup', this.handleMouseUp);
    },
    handleTouchStart(e) {
      e.preventDefault();
      this.startTime = new Date().getTime();
      document.addEventListener('touchend', this.handleTouchEnd);
    },
    handleMouseUp() {
      const endTime = new Date().getTime();
      if (endTime - this.startTime > this.threshold) {
        this.getImg();
      }
      document.removeEventListener('mouseup', this.handleMouseUp);
    },
    handleTouchEnd() {
      const endTime = new Date().getTime();
      if (endTime - this.startTime > this.threshold) {
        this.getImg();
      }
      document.removeEventListener('touchend', this.handleTouchEnd);
    },
  },
}

</script>
<style scoped lang='scss'>
.certifiBox{
  width: 100%;
  min-height:1500rem;
  .certBox{
    position: relative;
    // width: 750rem;
    height: 1000rem;
    margin: 30rem auto;
    background-size: 750rem auto;
    background-repeat: no-repeat;
  }
  .certiImg{
    display: block;
    height: 1000rem;
    margin: 0 auto 50rem;
  }
  .certNo{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20rem;
    line-height: 40rem;
    font-size: 26rem;
  }
  .noBox{
    display: inline-block;
    // line-height: 40rem;
    // border-bottom: 1rem solid #000;
  }
  .certText{
    position: absolute;
    font-size: 16rem;
  }
  .saveText{
    font-size: 24rem;
    color: #a8a8a8;
    text-align: center;
  }
  .btn{
    width: 500rem;
    line-height: 76rem;
    height: 76rem;
    text-align: center;
    border-radius: 38rem;
    border: 1px solid #b8211a;
    font-size: 28rem;
    margin:45rem auto;
  }
}
.emptyBox{
  font-size: 26rem;
  padding-top: 500rem;
  text-align: center;
  color: #b8211a;
}
#html2canvas-canvas {
    text-rendering: optimizeLegibility;
    font-smoothing: antialiased;
    -webkit-filter: blur(0);
    filter: none;
}
</style>