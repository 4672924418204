<template>
  <div class="donateBox">
    <!-- 全部项目列表 -->
    <div class="listBox">
      <div class="listTitle">
        <span class="titleText">我发起的一起捐（{{ totalNum }}）</span>
      </div>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <ul class="projectList">
            <li class="projectItem" v-for="(item, i) in list" :key="i">
              <div class="projectDetail">
                <div class="imgBox">
                  <img :src="item.donated_cover" class="coverImg" />
                </div>
                <div class="contentBox">
                  <div class="itemTitle">{{ item.username }}</div>
                  <div class="itemDis">
                    {{ item.donated_info }}
                  </div>
                  <div class="itemNum">
                    <div class="itemNumLeft">
                      <div class="doneMoney">已捐</div>
                      <div class="moneyNum">
                        <span class="colorNum"
                          >{{ item.donated_money ? donated_money : 0 }}/</span
                        >{{ item.target_money ? item.target_money : "不限" }}
                      </div>
                    </div>
                    <div class="moneyNum">
                      <span class="colorNum">{{ item.jk_num }}</span
                      >笔
                    </div>
                  </div>
                </div>
              </div>
              <div class="sloganBox">
                <span class="solganTitle">口号：</span>
                {{ item.slogan }}
              </div>
              <div class="moreBox">
                <div class="moreBtn" @click="goDetail(item.together_id)">
                  查看详情
                  <img src="../assets/right.png" class="rightIcon" />
                </div>
              </div>
            </li>
          </ul>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="bottomBox">
      共募捐 <span class="colorNum">{{ donated ? donated : 0 }}元</span>
    </div>
  </div>
</template>

<script>
import { getMyLaunch } from "../api";
export default {
  name: "myDonate",

  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      //分页信息
      pageIndex: 0,
      totalNum: 10,
      donated: 0,
    };
  },

  mounted() {},

  methods: {
    //获取项目列表
    async getList() {
      const res = await getMyLaunch(this.pageIndex);
      if (res.status == 200) {
        console.log(res, "res<<<<");
        this.totalNum = res.data.total;
        this.list = this.list.concat(res.data.data);
        this.refreshing = false;
        this.loading = false;
        this.donated = res.data.donated.toFixed(2);
      } else {
        this.$toast(res.err_msg);
      }
    },
    // 获取列表数据
    onLoad() {
      if (this.list.length < this.totalNum) {
        console.log(this.pageIndex, "第几页");
        this.pageIndex += 1;
        this.getList();
      } else {
        this.finished = true;
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pageIndex = 1;
      this.list = [];
      this.getList();
    },
    //去往详情页面
    goDetail(id) {
      this.$router.push({ path: `/project/share?together_id=${id}` });
    },
  },
};
</script>

<style lang="scss" scoped>
.donateBox {
  background-color: #f5f5f5;
  min-height: 100vh;
}
.listBox {
  width: 100%;
  padding: 55rem 40rem 100rem;
  background-color: #fff;
  .listTitle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 68rem;
    border-bottom: 1rem solid #e7e7e7;

    .titleText {
      font-size: 30rem;
      font-weight: 700;
      color: #b8211a;
    }

    .titleLine {
      width: 116rem;
      height: 4rem;
      background-color: #b8211a;
    }
  }

  .projectItem {
    width: 100%;
    padding: 30rem 0;
    border-bottom: 1px solid #e7e7e7;

    .projectDetail {
      display: flex;

      .imgBox {
        width: 216rem;
        height: 216rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 8rem;
        margin-right: 30rem;

        .coverImg {
          width: 100%;
        }
      }

      .contentBox {
        flex: 1;

        .itemTitle {
          width: 100%;
          font-size: 26rem;
          color: #333;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          /*行数*/
          display: -webkit-box;
          -webkit-box-orient: vertical;
          margin-bottom: 20rem;
          font-weight: 600;
        }

        .itemDis {
          width: 100%;
          font-size: 24rem;
          color: #333;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          /*行数*/
          display: -webkit-box;
          -webkit-box-orient: vertical;
          margin-bottom: 18rem;
          line-height: 38rem;
        }

        .itemNum {
          display: flex;
          justify-content: space-between;

          .itemNumLeft {
            display: flex;
            align-items: center;

            .doneMoney {
              width: 48rem;
              height: 30rem;
              text-align: center;
              line-height: 30rem;
              border-radius: 8rem;
              color: #f79d01;
              font-size: 18rem;
              border: 1rem solid #f79d01;
              margin-right: 10rem;
            }
          }

          .moneyNum {
            font-size: 24rem;
            color: #3c3c3c;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.moreBox {
  margin-top: 20rem;
  display: flex;
  justify-content: flex-end;

  .moreBtn {
    width: 120rem;
    height: 40rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16rem;
    color: #b8211a;
    border: 1px solid #b8211a;
    border-radius: 8rem;

    .rightIcon {
      width: 7rem;
      height: 13rem;
      margin-left: 10rem;
    }
  }
}
.sloganBox {
  display: flex;
  align-items: center;
  font-size: 22rem;
  color: #5d5d5d;
  margin-top: 35rem;
  .solganTitle {
    font-weight: 600;
    color: #000;
  }
}
.bottomBox {
  padding: 30rem 0 100rem;
  font-size: 22rem;
  text-align: center;
  color: #999999;
}
.colorNum {
  color: #b8211a;
}
</style>
