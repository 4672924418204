<template>
  <div class="commonBox donateBox">
    <!-- 项目概况 -->
    <div class="projectDetail">
      <img :src="info.cover" class="projectCover">
      <div class="projectBox">
        <div class="projectTitle">{{ info.name }}</div>
        <div class="projectDes">{{info.info}}</div>
      </div>
    </div>
    <!-- 捐赠表单 -->
    <div class="donateForm">
      <div class="formItem">
        <van-field v-model="donateForm.initiator" label="发起方"  maxlength="15" placeholder="请输入发起方"/>
      </div>
      <div class="formItem">
        <van-field v-model="donateForm.targetMoney" type="digit" maxlength="7" label="筹款目标(元)" placeholder="默认不限" />
      </div>
      <div class="formItem">
        <van-field v-model="donateForm.slogan" type="textarea" maxlength="150" label="发起口号" placeholder="请输入口号" />
      </div>
    </div>
    <!-- 提交按钮 -->
    <div class="submitBtn" @click="submitForm">{{isSubmit?'提交中...':'提&nbsp;&nbsp;交'}}</div>
  </div>
</template>

<script>
import {getProjectDonateInfo,sendTogetherDonate} from '@/api/index.js'
export default {
  name: 'donateTogether',
  data () {
    return {
      //项目id
      projectId:"",
      info:{},
      donateForm:{
        initiator:"",//发起方
        targetMoney:"",//筹款目标
        slogan:"",//口号
      },
      isSubmit:false,
    }
  },
  components: {

  },
  mounted() {
    this.projectId = this.$route.query.id
    this.getDetail()
  },
  methods: {
    //获取详情
    async getDetail(){
      const res = await getProjectDonateInfo(this.projectId)
      if(res){
          if(res.status==200){
          this.info = res.data
          this.donateForm.slogan = this.info.tpl_together
        }else{
          this.$toast(res.err_msg)
        }
      }
    },
    //提交
    async submitForm(){
      if(this.isSubmit){
        return false
      }
      if(!this.donateForm.initiator.trim()){
        this.$toast("请输入发起人")
      }else{
        this.isSubmit = true
        let params = {
          item_id:this.projectId,
          launch_name:this.donateForm.initiator,
          slogan:this.donateForm.slogan,
          target_money:this.donateForm.targetMoney,
        }
        //发送请求
        const res = await sendTogetherDonate(params)
        this.isSubmit = false
        if(res.status==200){
          this.$toast({
            message:res.data.msg,
            duration:1000,
            onClose:()=>{
              this.$router.push({ path:`/project/share?together_id=${res.data.together_id}`})
            }
          })
        }else{
          this.$toast(res.err_msg)
        }
      }
    }
  },
}

</script>
<style scoped lang='scss'>
.donateBox{
  padding: 100rem 40rem;
}
.projectDetail{
  display: flex;
  margin-bottom: 55rem;
  .projectCover{
    width: 160rem;
    height: 160rem;
    margin-right: 40rem;
  }
  .projectBox{
    flex:1;
    padding-top: 5rem;
    .projectTitle{
      width: 100%;
      font-size: 28rem;
      font-weight: 600;
      color: #121212;
      margin-bottom: 20rem;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      /*行数*/
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .projectDes{
      font-size: 20rem;
      color: #333333;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      /*行数*/
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
.donateForm{
  background-color: #fff;
  margin: 0 auto;
  padding:10rem 40rem 20rem;
  border-radius: 8rem;
  margin-bottom: 65rem;
  .formItem{
    border-bottom: 1px solid #e7e7e7;
  }
  .formItem:last-child{
    border-bottom: 0;
  }
  ::v-deep .van-field{
    padding:45rem 60rem 20rem 0;
  }
  ::v-deep .van-field__label {
    width: 160rem;
    font-size: 26rem;
    font-weight: 700;
    color: #000;
  }
  ::v-deep .van-field__control {
    font-size: 24rem;
    color: #333333;
  }
}
.submitBtn{
  margin: 0 auto;
  text-align: center;
  line-height: 82rem;
  border-radius: 8rem;
  color:#fff;
  font-size: 30rem;
  background-color: #b8211a;
}
</style>