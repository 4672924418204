<template>
  <div class="commonBox paySuccessBox">
    <van-icon name="alipay" size="80" color="#027AFF"/>
    <div class="success">订单号：{{ outTradeNo }}</div>
    <div class="success">支付金额：¥ {{totalAmount}}</div>
    <div class="success">支付成功</div>
    <div class="btns">
      <div class="loginBtn emptyClass" @click="goProject">返回项目</div>
      <div class="loginBtn" @click="goCert">查看证书</div>
    </div>
    
  </div>
</template>

<script>
import {getOrderInfo} from '@/api/index.js'
export default {
  name:"detail",
  data () {
    return {
      outTradeNo:"",//订单号
      totalAmount:"",//交易金额
      itemId:"",
      logId:"",
    }
  },
  components: {

  },
  mounted() {
    this.outTradeNo = this.$route.query.out_trade_no;
    this.totalAmount = this.$route.query.total_amount;
    this.getInfo()
  },
  methods: {
    // 根据订单号获取项目信息
    async getInfo(){
      const res = await getOrderInfo(this.outTradeNo)
      if(res.status==200){
        this.itemId = res.data.item_id
        this.logId = res.data.log_id
      }else{
        this.$toast(res.err_msg);
      }
    },
    goProject(){
      this.$router.push({path:`/project/projectDetail?id=${this.itemId}`})
    },
    //查看证书
    goCert(){
      this.$router.push({ path:`/project/certificate?outTradeNo=&logId=${this.logId}&itemId=${this.itemId}`})
    }
  },
}

</script>
<style scoped lang='scss'>
.paySuccessBox{
  padding-top: 300rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .success{
    margin: 20rem 0 10rem;
    font-size: 30rem;
  }
  .btns{
    // display: flex;
    // width: 100%;
    // justify-content: space-between;
  }
  .loginBtn {
    width: 600rem;
    height: 82rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30rem;
    color: #fff;
    background-color: #b8211a;
    border-radius: 41rem;
    margin: 65rem auto 30rem;
  }
  .emptyClass{
    color: #b8211a;
    background-color: #fff;
    border: 1px solid #b8211a;
  }
}
</style>