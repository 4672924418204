<template>
  <div>
    <div class="commonBox projectBox">
      <!-- 顶部名称 -->
      <div class="topBox">
        <img :src="schoolLogo || '../assets/listLogo.png'" class="logoImg" />
        <span class="logoTitle">{{ schoolName }}</span>
      </div>
      <!-- 搜索框 -->
      <form action="">
        <van-search
          v-model="keywords"
          shape="round"
          background="transparent"
          placeholder="请输入要搜索的内容"
          @search="onSearch"
        />
      </form>
      
    </div>
    <!-- 全部项目列表 -->
    <div class="listBox">
      <div class="listTitle">
        <span class="titleText">全部项目</span>
        <span class="titleLine"></span>
      </div>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <ul class="projectList">
            <li class="projectItem" v-for="(item, i) in list" :key="i">
              <div class="projectDetail">
                <div class="imgBox">
                  <img :src="item.cover" class="coverImg" />
                </div>
                <div class="contentBox">
                  <div class="itemTitle">{{ item.name }}</div>
                  <div class="itemDis">{{ item.info }}</div>
                  <div class="itemNum">
                    <div class="itemNumLeft">
                      <div class="doneMoney">已捐</div>
                      <div class="moneyNum">
                        <span class="colorNum">{{ item.donated_money }}/</span
                        >{{ item.funding * 1 || "不限" }}
                      </div>
                    </div>
                    <div class="moneyNum">
                      <span class="colorNum">{{ item.donated_num }}</span
                      >笔
                    </div>
                  </div>
                </div>
              </div>
              <div class="moreBox">
                <div class="moreBtn" @click="goDetail(item.id)">
                  查看详情
                  <img src="../assets/right.png" class="rightIcon" />
                </div>
              </div>
            </li>
          </ul>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { getAllProject } from "@/api/index.js";
export default {
  name: "allProject",

  data() {
    return {
      schoolId: "",
      keywords: "",
      schoolName: "",
      schoolLogo: "",
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      //分页信息
      pageIndex: 0,
      totalNum: 50,
    };
  },

  mounted() {
    //测试用
    // localStorage.clear()
    // sessionStorage.clear()


    this.schoolId = this.$route.query.school_id || 2;
    localStorage.setItem('schoolId',this.schoolId)
    //判断是不是微信 如果是微信 判断是否登录过 没有登录过先授权登录
    let isWechat = localStorage.getItem('isWechat')
    let token = localStorage.getItem('token')
    if(isWechat==1&&!token){
      this.$router.push({ path: "/wechatLogin" });
    }
  },

  methods: {
    //搜索事件
    onSearch() {
      this.list = [];
      this.pageIndex = 1;
      this.getList();
    },
    //获取项目列表
    async getList() {
      let params = {
        school_id: this.schoolId,
        keyword: this.keywords,
        page: this.pageIndex,
      };
      const res = await getAllProject(params);
      if (res.status == 200) {
        this.schoolName = res.data.school_name;
        this.schoolLogo = res.data.school_logo;
        this.totalNum = res.data.lists.total;
        this.list = [...this.list, ...res.data.lists.data];
        this.refreshing = false;
        this.loading = false;
      } else {
        this.$toast(res.err_msg);
      }
    },
    // 获取列表数据
    onLoad() {
      if (this.list.length < this.totalNum) {
        console.log(this.pageIndex, "第几页");
        this.pageIndex += 1;
        this.getList();
      } else {
        this.finished = true;
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pageIndex = 1;
      this.list = [];
      this.getList();
    },
    //去往详情页面
    goDetail(id) {
      this.$router.push({ path: `/project/projectDetail?id=${id}` });
    },
  },
};
</script>

<style lang="scss" scoped>
.projectBox {
  height: 250rem;
  overflow: hidden;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
}

.topBox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 45rem 0 30rem;

  .logoImg {
    width: 48rem;
    height: 46rem;
    border-radius: 23rem;
    margin-right: 15rem;
  }

  .logoTitle {
    font-size: 30rem;
    font-weight: 700;
    color: #333;
  }
}

::v-deep .van-field__left-icon .van-icon {
  color: #b8211a !important;
  font-weight: 700;
}

::v-deep .van-search {
  padding: 20rem 40rem;
}

::v-deep .van-search__content {
  background: #fff;
}

.listBox {
  width: 100%;
  margin-top: 250rem;
  padding: 45rem 40rem;

  .listTitle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 68rem;
    border-bottom: 1rem solid #e7e7e7;

    .titleText {
      font-size: 30rem;
      font-weight: 700;
    }

    .titleLine {
      width: 116rem;
      height: 4rem;
      background-color: #b8211a;
    }
  }

  .projectItem {
    width: 100%;
    padding: 30rem 0;
    border-bottom: 1px solid #e7e7e7;

    .projectDetail {
      display: flex;

      .imgBox {
        width: 216rem;
        height: 216rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 8rem;
        margin-right: 30rem;

        .coverImg {
          width: 100%;
        }
      }

      .contentBox {
        flex: 1;

        .itemTitle {
          width: 100%;
          font-size: 26rem;
          color: #333;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          /*行数*/
          display: -webkit-box;
          -webkit-box-orient: vertical;
          margin-bottom: 20rem;
          font-weight: 600;
        }

        .itemDis {
          width: 100%;
          font-size: 24rem;
          color: #333;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          /*行数*/
          display: -webkit-box;
          -webkit-box-orient: vertical;
          margin-bottom: 18rem;
          line-height: 38rem;
        }

        .itemNum {
          display: flex;
          justify-content: space-between;

          .itemNumLeft {
            display: flex;
            align-items: center;

            .doneMoney {
              width: 48rem;
              height: 30rem;
              text-align: center;
              line-height: 30rem;
              border-radius: 8rem;
              color: #f79d01;
              font-size: 18rem;
              border: 1rem solid #f79d01;
              margin-right: 10rem;
            }
          }

          .moneyNum {
            font-size: 24rem;
            color: #3c3c3c;
            font-weight: 600;
          }

          .colorNum {
            color: #b8211a;
          }
        }
      }
    }
  }
}

.moreBox {
  margin-top: 20rem;
  display: flex;
  justify-content: flex-end;

  .moreBtn {
    width: 120rem;
    height: 40rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16rem;
    color: #b8211a;
    border: 1px solid #b8211a;
    border-radius: 8rem;

    .rightIcon {
      width: 7rem;
      height: 13rem;
      margin-left: 10rem;
    }
  }
}
</style>
