
<template>
  <div>
    <div class="commonBox projectBox">
      <div class="searchLine">
         <!-- 搜索框 -->
         <form action="">
          <van-search v-model="keywords" shape="round" background="transparent" placeholder="搜索姓名" @search="onSearch" />
         </form>
         <div class="showNum">
          {{allNum}}笔 | <span class="colorNum">{{totalMoney}}元</span>
         </div>
      </div>
      <div class="tabBox">
        <div :class="activeTab==item.id?'tabItem activeTab':'tabItem'" v-for="(item,i) in tabList" :key="i" @click="changeTab(item.id)">{{item.name}}</div>
      </div>
     
    </div>
    <!-- 全部项目列表 -->
    <div class="listBox">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <ul class="projectList">
            <li class="projectItem" v-for="(item,i) in list" :key="i">
              <div class="projectDetail">
                <div class="imgBox">
                  <img :src="item.avatar?item.avatar:defaultImage" class="coverImg">
                </div>
                <div class="contentBox">
                  <div class="itemTitle">{{item.name }}</div>
                  <div class="itemDis">捐赠{{item.wupin?(item.wupin+','):''}}{{item.money?('金额'+item.money+'元'):''}}</div>
                </div>
                <div class="timeBox">{{item.donate_date}}</div>
              </div>
            </li>
          </ul>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import {getOffLineDonateList} from '@/api/index.js'
export default {
  name: 'moreDonateList',

  data() {
    return {
      projectId:"",
      keywords: "",
      //tab栏
      tabList:[
        {id:'time',name:"按时间"},
        {id:'money',name:"按捐款金额"},
      ],
      activeTab:'time',
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      //分页信息
      pageIndex:0,
      totalNum:50,
      allNum:0,
      totalMoney:0,
      defaultImage: require('@/assets/avatar.png'),
    };
  },

  mounted() {
    this.projectId = this.$route.query.id
  },

  methods: {
    //获取列表数据
    async getList(){
      let params ={
        username:this.keywords,
        sort_type:this.activeTab,
        page:this.pageIndex,
        item_id:this.projectId,
      }
      const res = await getOffLineDonateList(params)
      if(res.status==200){
        this.allNum = res.data.all_num
        this.totalNum = res.data.total
        this.totalMoney = res.data.total_money
        this.list=[...this.list,...res.data.data]
        this.refreshing = false
        this.loading = false
      }else{
        this.$toast(res.err_msg)
      }
    },
    //切换tab
    changeTab(id){
      this.activeTab = id
      this.list = [];
      this.pageIndex = 1;
      this.getList();
    },
    //搜索事件
    onSearch() {
      this.list = [];
      this.pageIndex = 1;
      this.getList();
    },
    // 获取列表数据
    onLoad() {
      if (this.list.length < this.totalNum) {
        console.log(this.pageIndex, "第几页");
        this.pageIndex += 1;
        this.getList();
      } else {
        this.finished = true;
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pageIndex = 1;
      this.list = [];
      this.getList();
    },
     //去往详情页面
     goDetail(id){
      this.$router.push({ path:`/project/projectDetail?id=${id}`})
    }
  },
};
</script>

<style lang="scss" scoped>
.projectBox {
  height: 250rem;
  overflow: hidden;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
}
.searchLine{
  display: flex;
  align-items: center;
}
::v-deep .van-field__left-icon .van-icon {
  color: #b8211a !important;
  font-weight: 700;
}

::v-deep .van-search {
  padding: 45rem 40rem 30rem;
  width: 360rem;
}

::v-deep .van-search__content {
  background: #fff;
}
.showNum{
  flex:1;
  padding-right: 40rem;
  font-size:28rem;
  text-align: right;
  font-weight: 600;
}
.tabBox{
  display: flex;
  width: 670rem;
  margin:0 auto;
  font-size: 26rem;
  font-weight: 700;
  border-radius: 8rem;
  border: 1px solid #b8211a;
  .tabItem{
    width: 50%;
    height: 72rem;
    line-height: 72rem;
    text-align: center;
  }
  .activeTab{
    color:#fff;
    border-radius: 8rem;
    background-color: #b8211a;
  }
}
.listBox {
  width: 100%;
  margin-top: 250rem;
  padding: 45rem 40rem;

  .projectItem {
    width: 100%;
    padding: 30rem 0;
    border-bottom: 1px solid #e7e7e7;

    .projectDetail {
      display: flex;

      .imgBox {
        width: 110rem;
        height: 110rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 55rem;
        margin-right: 30rem;

        .coverImg {
          width: 100%;
        }
      }

      .contentBox {
        flex: 1;
        padding:10rem 0;
        .itemTitle {
          width: 100%;
          font-size: 26rem;
          color: #333;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          /*行数*/
          display: -webkit-box;
          -webkit-box-orient: vertical;
          margin-bottom: 10rem;
          font-weight: 600;
        }

        .itemDis {
          width: 100%;
          font-size: 24rem;
          color: #333;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          /*行数*/
          display: -webkit-box;
          -webkit-box-orient: vertical;
          margin-bottom: 18rem;
          line-height: 38rem;
        }

        .itemNum {
          display: flex;
          justify-content: space-between;

          .itemNumLeft {
            display: flex;
            align-items: center;

            .doneMoney {
              width: 48rem;
              height: 30rem;
              text-align: center;
              line-height: 30rem;
              border-radius: 8rem;
              color: #f79d01;
              font-size: 18rem;
              border: 1rem solid #f79d01;
              margin-right: 10rem;
            }
          }

          .moneyNum {
            font-size: 24rem;
            color: #3c3c3c;
            font-weight: 600;
          }

          
        }
      }
    }
  }
}
.timeBox{
  font-size: 22rem;
  color: #333;
  padding-top: 10rem;
}
.colorNum {
  color: #b8211a;
}
</style>